import { message } from 'ant-design-vue';
import Axios, { AxiosError } from 'axios';

import { AnalyticData } from '@/interfaces/analyticData';
import { AuthorizeResponse, RefreshSessionResponse } from '@/interfaces/auth';
import { DroneSurvey } from '@/interfaces/droneSurvey';
import { Parcel } from '@/interfaces/parcel';
import { Regions } from '@/interfaces/regions';
import { Weeds } from '@/interfaces/weeds';
import { WeedsCurationData } from '@/interfaces/weedsCurationData';
import { WeedsStatistics } from '@/interfaces/weedsStatistics';
import { FeatureCollection, Polygon } from '@turf/helpers';

export interface ApiResponse {
  success: boolean;
}

class API {
  static _URL_PREFIX = '/api';
  static _CURATION_SERVICE_URL = `${process.env.VUE_APP_CURATION_SERVICE}${API._URL_PREFIX}`;
  static _PRODUCT_MANAGER_URL = `${process.env.VUE_APP_PRODUCT_MANAGER}${API._URL_PREFIX}`;

  static STORAGE_BASE_URL = 'https://storage.googleapis.com';
  static WEEDS_BUCKET = process.env.VUE_APP_WEEDS_FOLDER;

  static getDroneSurvey(surveyId: string): Promise<DroneSurvey> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/survey?surveyId=${surveyId}&source=drone`, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError('failed to load survey', err));
  }

  static getParcel(parcelId: string): Promise<Parcel> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/parcel?id=${parcelId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch((err) => API.handleError('failed to load parcel', err));
  }

  static async getWeedsAnalytic(
    surveyId: string,
    lastUpdate: string,
    isOriginal?: boolean
  ): Promise<FeatureCollection<Polygon>> {
    const storageFileName = isOriginal ? 'original' : 'curated';
    try {
      return API.getBlobFile(surveyId, lastUpdate, storageFileName);
    } catch (error) {
      API.handleError(`Could not load from GCP storage ${storageFileName}.json`, error as AxiosError);
    }
  }

  static async getBlobFile(surveyId: string, lastUpdate: string, storageFileName: string): Promise<any> {
    const { data } = await Axios.get(
      `${API.STORAGE_BASE_URL}/${API.WEEDS_BUCKET}/${surveyId}/${storageFileName}.json?v=${lastUpdate}`
    );
    return data;
  }

  static async saveRegionsStatus(surveyId: string, regions: Regions): Promise<void> {
    try {
      const requestData = {
        surveyId,
        regions
      };
      const { data } = await Axios.post(`${API._CURATION_SERVICE_URL}/weeds_curation/regions`, requestData, {
        withCredentials: true
      });
      return data;
    } catch (error) {
      API.handleError(`Could not save regions`, error as AxiosError);
    }
  }

  static async saveAnalyticCurated(surveyId: string, curated: Weeds): Promise<void> {
    try {
      const requestData = {
        surveyId,
        curated
      };
      const { data } = await Axios.patch(`${API._CURATION_SERVICE_URL}/weeds_curation/analytic-curated`, requestData, {
        withCredentials: true
      });
      return data;
    } catch (error) {
      API.handleError(`Could not save curated analytic`, error as AxiosError);
    }
  }

  static async getStatistics(surveyId: string, lastUpdate: string): Promise<WeedsStatistics> {
    try {
      const result = await Axios.get(
        `https://storage.googleapis.com/${process.env.VUE_APP_WEEDS_FOLDER}/${surveyId}/stats.json?v=${lastUpdate}`
      );
      return result.data;
    } catch (error) {
      API.handleError('Could not load stats.json', error as AxiosError);
    }
  }

  static async recalculateWeedsAnalytic(surveyId: string, curated: Weeds): Promise<void> {
    try {
      const requestData = {
        surveyId,
        curated
      };
      const { data } = await Axios.post(`${API._CURATION_SERVICE_URL}/weeds_curation/complete`, requestData, {
        withCredentials: true
      });
      return data;
    } catch (error: unknown) {
      const response = (error as AxiosError).response;
      if (response.status === 409) {
        return API.handleError('Can not complete this survey currently in progress', error as AxiosError);
      }
      API.handleError('failed to recalculate Weeds Analytic', error as AxiosError<any>);
    }
  }

  static async getAnalyticStatus(surveyId: string, analytic: string): Promise<AnalyticData> {
    try {
      const result = await Axios.get(
        `${API._PRODUCT_MANAGER_URL}/analytic-status?id=${surveyId}&analytic=${analytic}&type=drone`,
        {
          withCredentials: true
        }
      );
      return result.data;
    } catch (error) {
      API.handleError('failed to load Analytic Status', error as AxiosError);
    }
  }

  static async publishAnalytic(surveyId: string): Promise<void> {
    try {
      const result = await Axios.post(
        `${API._CURATION_SERVICE_URL}/survey_data/publish/${surveyId}`,
        { analyticType: 'weeds' },
        {
          withCredentials: true
        }
      );
      return result.data;
    } catch (error) {
      API.handleError('failed to publish analytic', error as AxiosError);
    }
  }

  static async unPublishAnalytic(surveyId: string): Promise<void> {
    try {
      const result = await Axios.post(
        `${API._CURATION_SERVICE_URL}/survey_data/unpublish/${surveyId}`,
        { analyticType: 'weeds' },
        {
          withCredentials: true
        }
      );
      return result.data;
    } catch (error) {
      API.handleError('failed to unPublish analytic', error as AxiosError);
    }
  }

  static authorize(role: string): Promise<AuthorizeResponse> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/authorize?role=${role}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(() => false);
  }

  static refreshSession(): Promise<RefreshSessionResponse> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/refresh-session`, { withCredentials: true }).then(
      (result) => result.data
    );
  }

  static updateWeedsCurationData(surveyId: string, payload: WeedsCurationData): Promise<void> {
    return Axios.patch(`${API._CURATION_SERVICE_URL}/weeds_curation_data/${surveyId}`, payload, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch((err) => {
        API.handleError('failed to update weeds curation data', err);
      });
  }

  static getWeedsCurationData(surveyId: string): Promise<WeedsCurationData> {
    return Axios.get(`${API._CURATION_SERVICE_URL}/weeds_curation_data/${surveyId}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch((err) => {
        if (err?.response?.status !== 404) {
          // WeedsCurationData may not exist for old surveys
          API.handleError('failed to load weeds curation data', err);
        }
      });
  }

  static updateWeedsAnalytic(surveyId: string, geoJson: FeatureCollection): Promise<void> {
    const payload = {
      surveyId,
      curated: geoJson
    };
    return Axios.patch(`${API._CURATION_SERVICE_URL}/weeds_analytic`, payload, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch((err) => {
        API.handleError('failed to update weeds analytic data', err);
      });
  }

  static handleError(clientError = 'something went wrong', serviceError: AxiosError<any>): void {
    let serviceErrorMsg = '';
    if (serviceError.response?.data?.message) {
      serviceErrorMsg = `${serviceError.response?.data?.message}, statusCode: ${serviceError.response.status}`;
    } else if (serviceError.response) {
      serviceErrorMsg = `${serviceError.message}, statusCode: ${serviceError.response.status}`;
    } else {
      serviceErrorMsg = serviceError.message;
    }

    message.error((createElement) => {
      return createElement('span', [
        createElement('span', clientError),
        createElement('br'),
        createElement('span', serviceErrorMsg)
      ]);
    }, 5);

    throw serviceError;
  }
}

export default API;
