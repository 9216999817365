export enum WeedsClassType {
  GW = 'GW',
  BRLOW = 'BRLOW',
  CW = 'CW',
  BRHIW = 'BRHIW'
}

export enum WeedsClassLabel {
  GW = 'Bush',
  BRLOW = 'Climbing',
  CW = 'Grassy',
  BRHIW = 'Colinão'
}
