
import Mapbox from 'mapbox-gl-vue';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { MapComponent } from '@/interfaces/mapComponent';
import {
  feature,
  Feature,
  FeatureCollection,
  featureCollection,
  LineString,
  multiPolygon,
  polygon,
  Polygon
} from '@turf/helpers';
import simplify from '@turf/simplify';
import cleanCoords from '@turf/clean-coords';
import area from '@turf/area';
import polygonToLine from '@turf/polygon-to-line';
import polygonClipping, { MultiPolygon, Ring } from 'polygon-clipping';
import center from '@turf/center';
import API from '@/services/api';
import mapboxgl, { LngLat } from 'mapbox-gl';
import { Parcel } from '@/interfaces/parcel';
import { message } from 'ant-design-vue';
import { PaintMode } from '@/components/Map/paint-mode';
import { tiles } from './tile-cover.js';
import { drawWeedsClassesStyles } from '@/components/Map/draw-styles';
import { DroneSurvey } from '@/interfaces/droneSurvey';
import { WeedsClassType } from '@/enum/weeds-class-type';
import WeedsClassPallete from '@/components/WeedsClassPallete.vue';
import constants from '@/services/constants';
import { tileToGeoJSON } from '@mapbox/tilebelt';
import { BackgroundSource } from '@/enum/backgroundSource';
import { Regions } from '@/interfaces/regions';
import { Weeds } from '@/interfaces/weeds';

enum DeleteMenu {
  DeleteTileSquares = 1,
  DeleteParcelSquares = 2
}

@Component({
  components: {
    Mapbox,
    WeedsClassPallete
  }
})
export default class Map extends Vue implements MapComponent {
  @Prop() backgroundSource: BackgroundSource;
  @Prop() areInnerTilesShown;

  WeedsClassType = WeedsClassType;
  DeleteMenu = DeleteMenu;
  weedsClassesColors = constants.weedsClassesColors;
  weedsClassesOptions = constants.weedsClassesOptions;
  tileStatus = constants.tileStatus;
  skipAreaPercentage = 50;
  weedsClassesToDelete: string[] = [];
  drawSelectedFeatureIds: string[] = null;

  private map: mapboxgl.Map;
  private draw: MapboxDraw;

  private readonly parcelPaint = { 'line-width': 3, 'line-color': 'darkgray' };

  private readonly rasterSourceId = 'rasterSource';
  private readonly rasterLayerId = 'rasterLayer';

  private readonly parcelSourceId = 'parcelSource';
  private readonly parcelLayerId = 'parcelLayer';

  private readonly parcelSquaresSourceId = 'parcelSquaresSource';
  private readonly parcelSquaresLayerId = 'parcelSquaresLayer';
  private readonly tilesPolygonLayerId = 'tilesPolygonLayer';

  private readonly smallSquaresSourceId = 'smallSquaresSource';
  private readonly smallSquaresLayerId = 'smallSquaresLayer';

  private readonly selectedTilesPolygonSourceId = 'selectedTilesPolygonSource';
  private readonly selectedTilesPolygonLayerId = 'selectedTilesPolygonLayer';

  private readonly weedsAnalyticSourceId = 'weedsAnalyticSource';
  private readonly weedsAnalyticLayerId = 'weedsAnalyticLayer';

  private readonly originalWeedsAnalyticSourceId = 'originalWeedsAnalyticSource';
  private readonly originalWeedsAnalyticLayerId = 'originalWeedsAnalyticLayer';

  private readonly innerParcelSquaresSourceId = 'innerParcelSquaresSource';
  private readonly innerParcelSquaresLayerId = 'innerParcelSquaresLayer';

  isPaintMode = false;
  isDeleteMode = false;
  isReclassifyMode = false;
  drawHistory: Array<any> = [];
  selectedWeedsClass = WeedsClassType.GW;
  isClassCurationMode = false;

  private parcel: Parcel = null;
  private survey: DroneSurvey = null;

  tilesStatus: Regions = {
    zoom: 19,
    grid: {}
  };
  private weedsCurated: Weeds = {
    zoom: 26,
    grid: {}
  };
  private selectedRegion: Feature<Polygon> = null;
  private regions: Array<Feature<Polygon>> = [];
  private innerRegions: Array<Feature<Polygon>> = [];
  private smallSquaresForSelectedRegion: Array<Feature<Polygon>> = [];

  @Prop()
  lastUpdate: string;

  mounted(): void {
    this.onLoad();
  }

  destroyed(): void {
    document.onkeyup = null;
  }

  get isEditable(): boolean {
    return this.$store.state.underCuration;
  }

  get isOperator() {
    const { Roles = [] } = this.$store.state.userInfo ?? {};
    return (
      Roles.includes(constants.roles.OPERATOR) ||
      Roles.includes(constants.roles.ADMIN) ||
      Roles.includes(constants.roles.MAHINDRA_ADMIN)
    );
  }

  @Watch('isEditable')
  onIsEditableChange(isEditable: boolean): void {
    if (!isEditable && this.selectedRegion) {
      this.onRegionClick(this.selectedRegion.properties.key);
    }
  }

  @Watch('backgroundSource')
  onBackgroundSourceChange(backgroundSource: BackgroundSource): void {
    let folder = process.env.VUE_APP_DRONE_TILES_FOLDER;
    let surveyId = this.$route.query.surveyId;
    if (!surveyId) {
      return;
    }
    switch (backgroundSource) {
      case BackgroundSource.CLASSIFIER:
        folder = process.env.VUE_APP_CLASSIFIER_TILES_FOLDER;
        surveyId = `weeds-${surveyId}`;
        break;
    }
    this.drawRasterTiles(`${folder}/${surveyId}`);
  }

  @Watch('selectedWeedsClass')
  onSelectedWeedsClassChanged(): void {
    this.onChangeWeedsClass();
  }

  get currentMapMode(): string {
    if (this.isPaintMode) {
      return 'Paint mode (keep Shift pressed to painting)';
    }
    if (this.isDeleteMode) {
      return 'Deletion mode (keep Shift pressed to painting)';
    }
    if (this.isReclassifyMode) {
      return 'Reclassify mode (keep Shift pressed to painting)';
    }
    return null;
  }

  get surveyId(): string {
    return this.$route.query.surveyId as string;
  }

  private createDrawLayer(): void {
    const paintMode = new PaintMode();
    paintMode.onDrawFinished = async (polygon: Feature<Polygon>) => {
      this.map.getCanvas().style.cursor = 'progress';
      setTimeout(() => {
        if (polygon) {
          const simplifiedPolygon = this.simplifyPolygon(polygon);
          if (this.isDeleteMode) {
            this.drawDeleteSquaresForPolygon(simplifiedPolygon);
          }
          if (this.isPaintMode) {
            this.drawSquaresForPolygon(simplifiedPolygon);
          }
          if (this.isReclassifyMode) {
            this.drawSquaresForPolygon(simplifiedPolygon);
          }
        }

        setTimeout(() => {
          this.map.getCanvas().style.cursor = 'crosshair';
        }, 300);
      }, 100);
    };

    this.draw = new MapboxDraw({
      userProperties: true,
      displayControlsDefault: false,
      styles: drawWeedsClassesStyles,
      modes: {
        paint: paintMode,
        ...MapboxDraw.modes
      }
    });
    this.map.addControl(this.draw);
  }

  private createParcelLayer(): void {
    this.map.addSource(this.parcelSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });
    this.map.addLayer({
      type: 'line',
      id: this.parcelLayerId,
      source: this.parcelSourceId,
      paint: this.parcelPaint
    });
  }

  private createInnnerParcelSquaresLayer(): void {
    this.map.addSource(this.innerParcelSquaresSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });
    this.map.addLayer({
      type: 'line',
      id: this.innerParcelSquaresLayerId,
      source: this.innerParcelSquaresSourceId,
      paint: { 'line-width': 2, 'line-color': ['get', 'color'], 'line-opacity': 1 }
    });
  }

  private createParcelSquaresLayer(): void {
    this.map.addSource(this.parcelSquaresSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });
    this.map.addLayer({
      type: 'line',
      id: this.parcelSquaresLayerId,
      source: this.parcelSquaresSourceId,
      paint: { 'line-width': 2, 'line-color': ['get', 'color'] }
    });
    this.map.addLayer({
      type: 'fill',
      id: this.tilesPolygonLayerId,
      source: this.parcelSquaresSourceId,
      paint: {
        'fill-opacity': 0
      }
    });

    this.map.on('click', this.tilesPolygonLayerId, (e) => {
      if (this.isEditable) {
        this.onRegionClick(e.features[0].properties.key);
      }
    });
    this.map.on('mouseenter', this.tilesPolygonLayerId, () => {
      if (this.isEditable) {
        this.map.getCanvas().style.cursor = 'pointer';
      }
    });
    this.map.on('mouseleave', this.tilesPolygonLayerId, () => {
      if (this.isEditable) {
        this.map.getCanvas().style.cursor = '';
      }
    });
  }

  private createSmallSquaresLayer(): void {
    this.map.addSource(this.smallSquaresSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });
    this.map.addLayer({
      type: 'line',
      id: this.smallSquaresLayerId,
      source: this.smallSquaresSourceId,
      paint: { 'line-width': 2, 'line-color': ['get', 'color'] }
    });
  }

  private createSelectedTilePolygonLayer(): void {
    this.map.addSource(this.selectedTilesPolygonSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });

    this.map.addLayer({
      type: 'line',
      id: this.selectedTilesPolygonLayerId,
      source: this.selectedTilesPolygonSourceId,
      paint: { 'line-width': 3, 'line-color': '#42e3f5' }
    });
  }

  private createOriginalWeedsAnalyticLayer(): void {
    this.map.addSource(this.originalWeedsAnalyticSourceId, {
      type: 'vector',
      tiles: [
        `https://storage.googleapis.com/${process.env.VUE_APP_WEEDS_FOLDER}/${
          this.surveyId
        }/vt_original/{z}/{x}/{y}.mvt?v=${new Date().getTime()}`
      ],
      minzoom: 10,
      maxzoom: 22
    });
    this.map.addLayer({
      type: 'fill',
      id: this.originalWeedsAnalyticLayerId,
      source: this.originalWeedsAnalyticSourceId,
      'source-layer': 'geojsonLayer',
      paint: {
        'fill-color': '#000'
      }
    });
  }

  private async createWeedsAnalyticLayer() {
    this.map.addSource(this.weedsAnalyticSourceId, {
      type: 'vector',
      tiles: [
        `https://storage.googleapis.com/${process.env.VUE_APP_WEEDS_FOLDER}/${
          this.surveyId
        }/vt_curated/{z}/{x}/{y}.mvt?v=${new Date().getTime()}`
      ],
      minzoom: 10,
      maxzoom: 22
    });
    this.map.addLayer({
      type: 'fill',
      id: this.weedsAnalyticLayerId,
      source: this.weedsAnalyticSourceId,
      'source-layer': 'geojsonLayer',
      paint: {
        'fill-color': [
          'match',
          ['get', 'tag'],
          WeedsClassType.GW,
          constants.weedsClassesColors.GW,
          WeedsClassType.BRHIW,
          constants.weedsClassesColors.BRHIW,
          WeedsClassType.BRLOW,
          constants.weedsClassesColors.BRLOW,
          WeedsClassType.CW,
          constants.weedsClassesColors.CW,
          'yellow'
        ]
      }
    });
  }

  onMapLoaded(map: mapboxgl.Map): void {
    this.map = map;
    this.createParcelLayer();
    this.createInnnerParcelSquaresLayer();
    this.createParcelSquaresLayer();
    this.createOriginalWeedsAnalyticLayer();
    this.createWeedsAnalyticLayer();
    this.createSmallSquaresLayer();
    this.createSelectedTilePolygonLayer();
    this.createDrawLayer();

    this.hideOriginalWeedsAnalytic();

    if (this.parcel) {
      this.drawData();
    }

    document.onkeyup = (e) => {
      if (e.shiftKey) {
        switch (e.code) {
          case 'KeyA':
            if (this.selectedRegion) {
              this.paint();
            }
            break;
          case 'KeyD':
            if (this.selectedRegion) {
              this.deletePainted();
            }
            break;
          case 'KeyZ':
            if (this.selectedRegion) {
              this.applyFromHistory();
            }
            break;
          case 'KeyF':
            if (this.selectedRegion && this.isClassCurationMode) {
              this.reclassifyPainted();
            }
            break;
          case 'KeyS':
            this.$emit('handleShowInnerTiles', !this.areInnerTilesShown as boolean);
            break;
          case 'KeyW':
            this.$emit('handleShowAnalytic');
            break;
        }
      } else {
        const weedClassSelected = constants.weedsClassesKeysMap[e.code];
        if (this.isClassCurationMode && weedClassSelected) {
          this.selectedWeedsClass = weedClassSelected;
        }
      }
    };
  }

  private async onLoad(): Promise<void> {
    const surveyId = this.$route.query.surveyId as string;
    if (!surveyId) {
      message.error('no survey id!', 5);
      this.$store.dispatch('showGlobalLoader', false);
      return;
    }

    try {
      this.$store.dispatch('showGlobalLoader', true);
      const survey = await API.getDroneSurvey(surveyId);
      if (!survey) {
        throw new Error(`Cant load survey ${surveyId}`);
      }
      this.survey = survey;
      this.parcel = await API.getParcel(survey.ParcelID);
      if (!this.parcel) {
        throw new Error(`Cant load parcel ${survey.ParcelID}`);
      }

      try {
        this.weedsCurated = await API.getBlobFile(surveyId, `${new Date().getTime()}`, 'curated-weeds');
      } catch {
        /* eslint-disable-next-line no-console */
        console.info('No weeds curated file');
      }

      try {
        this.tilesStatus = await API.getBlobFile(surveyId, `${new Date().getTime()}`, 'regions');
      } catch {
        /* eslint-disable-next-line no-console */
        console.info('No tiles status file');
      }

      this.drawData();
    } catch (err) {
      message.error('Something went wrong: ' + err, 5);
    } finally {
      this.$store.dispatch('showGlobalLoader', false);
    }
  }

  private drawData(): void {
    if (!this.map) {
      return;
    }

    const coordinates = center(this.parcel.Shape).geometry.coordinates;
    this.map.setCenter(new LngLat(coordinates[0], coordinates[1]));

    this.onBackgroundSourceChange(this.backgroundSource);
    this.drawParcel();
    this.drawInnerParcelTiles();
    this.drawParcelTiles();
  }

  stopDrawing(): void {
    this.draw.changeMode('simple_select');
    this.isPaintMode = false;
    this.isDeleteMode = false;
    this.isReclassifyMode = false;
    this.drawSelectedFeatureIds = null;
    this.map.getCanvas().style.cursor = 'unset';
  }

  private drawRasterTiles(folder: string): void {
    this.cleanupLayer(this.rasterLayerId, this.rasterSourceId);

    if (this.backgroundSource === BackgroundSource.NONE) {
      return;
    }

    this.map.addSource(this.rasterSourceId, {
      type: 'raster',
      tiles: [`https://storage.googleapis.com/${folder}/{z}/{x}/{y}.png?v=${this.survey.LastUpdate}`],
      bounds: [this.parcel.LLLong, this.parcel.LLLat, this.parcel.URLong, this.parcel.URLat],
      tileSize: 256
    });
    this.map.addLayer(
      {
        type: 'raster',
        id: this.rasterLayerId,
        source: this.rasterSourceId
      },
      this.parcelLayerId
    );
  }

  private drawParcel(): void {
    if (this.parcel && this.parcel.Shape) {
      this.updateGeoJsonSource(this.parcelSourceId, this.parcel.Shape);
    }
  }

  private onRegionClick(key: string): void {
    if (this.selectedRegion?.properties.key === key) {
      this.selectedRegion = null;
      this.stopDrawing();
    } else {
      this.selectedRegion = this.regions.find((region: Feature<Polygon>) => region.properties.key === key);
    }
    this.updateGeoJsonSource(
      this.selectedTilesPolygonSourceId,
      featureCollection(this.selectedRegion ? [this.selectedRegion] : [])
    );
    this.initSquaresForSelectedRegion();
    this.drawHistory = [];
    this.saveToHistory({
      added: {},
      deleted: {}
    });
    this.updateGeoJsonSource(this.smallSquaresSourceId, featureCollection(this.smallSquaresForSelectedRegion));
  }

  private getSelectedRegionBbox(): number[] {
    if (this.selectedRegion) {
      const [xS, yS] = this.selectedRegion.properties.key.split('_');
      const x = parseInt(xS);
      const y = parseInt(yS);
      const x1 = x + 1;
      const y1 = y + 1;
      const delta = Math.pow(2, this.weedsCurated.zoom - this.tilesStatus.zoom);
      return [x * delta, y * delta, x1 * delta, y1 * delta];
    }
    return null;
  }

  private isTileInBbox(tileX: number, tileY: number, selectedRegionBbox: number[]): boolean {
    return (
      selectedRegionBbox &&
      tileX >= selectedRegionBbox[0] &&
      tileX < selectedRegionBbox[2] &&
      tileY >= selectedRegionBbox[1] &&
      tileY < selectedRegionBbox[3]
    );
  }

  private drawParcelTiles(): void {
    if (this.parcel?.Shape) {
      const parcelTiles = tiles(this.parcel.Shape.features[0].geometry, {
        min_zoom: this.tilesStatus.zoom,
        max_zoom: this.tilesStatus.zoom
      });
      this.regions = parcelTiles
        .sort((a, b) => {
          return a[1] - b[1] || a[0] - b[0];
        })
        .map((tile) => {
          const key = tile[0] + '_' + tile[1];
          const status = this.tilesStatus.grid[key] || constants.tileStatus.ORIGINAL;
          return feature(tileToGeoJSON(tile), {
            key,
            color: constants.tileStatusColors[status]
          });
        });
      this.updateGeoJsonSource(this.parcelSquaresSourceId, featureCollection(this.regions));
    }
  }

  private drawInnerParcelTiles(): void {
    if (this.parcel?.Shape) {
      const innerParcelTiles = tiles(this.parcel.Shape.features[0].geometry, {
        min_zoom: 22,
        max_zoom: 22
      });

      this.innerRegions = innerParcelTiles
        .sort((a, b) => a[1] - b[1] || a[0] - b[0])
        .map((tile) => {
          const key = tile[0] + '_' + tile[1];
          const status = this.tilesStatus.grid[key] || constants.tileStatus.ORIGINAL;
          return feature(tileToGeoJSON(tile), {
            key,
            color: 'white'
          });
        });

      this.updateGeoJsonSource(this.innerParcelSquaresSourceId, featureCollection(this.innerRegions));
    }
  }

  private initSquaresForSelectedRegion(): void {
    const selectedRegionBbox = this.getSelectedRegionBbox();
    const features = [];
    Object.keys(this.weedsCurated.grid).forEach((key) => {
      const [x, y] = key.split('_');
      const tile = [parseInt(x), parseInt(y), this.weedsCurated.zoom];
      if (this.isTileInBbox(tile[0], tile[1], selectedRegionBbox)) {
        features.push(
          feature(tileToGeoJSON(tile), {
            key,
            color: constants.weedsClassesColors[Object.keys(this.weedsCurated.grid[key])[0] as any]
          })
        );
      }
    });
    this.smallSquaresForSelectedRegion = features;
  }

  private getSkipTilesKeys(polygonTiles: Array<[number, number, number]>, poly: Feature<Polygon>): string[] {
    const skipTiles = [];
    polygonTiles.forEach((tile) => {
      const f = feature(tileToGeoJSON(tile));
      const intersection = polygonClipping.intersection(
        f.geometry.coordinates as Ring[],
        poly.geometry.coordinates as Ring[]
      );
      if (intersection && intersection.length) {
        const intersect = intersection.length === 1 ? polygon(intersection[0]) : multiPolygon(intersection);
        const intersectAreaPercentage = (area(intersect) * 100) / area(f);
        if (intersectAreaPercentage <= this.skipAreaPercentage) {
          const key = tile[0] + '_' + tile[1];
          skipTiles.push(key);
        }
      }
    });
    return skipTiles;
  }

  private drawDeleteSquaresForPolygon(poly: Feature<Polygon>): void {
    const polygonTiles = tiles(poly.geometry, {
      min_zoom: this.weedsCurated.zoom,
      max_zoom: this.weedsCurated.zoom
    });

    const skipTiles = this.getSkipTilesKeys(polygonTiles, poly);
    const keysToDelete = [];
    const deleted = {};
    polygonTiles.forEach((tile) => {
      const key = tile[0] + '_' + tile[1];
      if (!skipTiles.includes(key) && this.weedsCurated.grid[key]) {
        deleted[key] = this.weedsCurated.grid[key];
        delete this.weedsCurated.grid[key];
        keysToDelete.push(key);
      }
    });
    if (keysToDelete.length) {
      this.smallSquaresForSelectedRegion = this.smallSquaresForSelectedRegion.filter(
        (feature) => !keysToDelete.includes(feature.properties.key)
      );
      this.updateGeoJsonSource(this.smallSquaresSourceId, featureCollection(this.smallSquaresForSelectedRegion));
      this.saveToHistory({
        added: {},
        deleted
      });
    }
  }

  onDeleteAllSquares({ key }: { key: DeleteMenu }): void {
    if (!this.weedsClassesToDelete.length) {
      message.error('Please, select at least one weeds class to delete');
      return;
    }

    switch (Number(key)) {
      case DeleteMenu.DeleteTileSquares: {
        this.deleteSquaresForPolygon(this.weedsClassesToDelete);
        message.success('Removed all squares from selected tile');
        break;
      }
      case DeleteMenu.DeleteParcelSquares: {
        this.deleteParcelSquares(this.weedsClassesToDelete);
        message.success('Removed squares from all tiles');
        break;
      }
      default:
        return;
    }
  }

  private deleteParcelSquares(weedsClassesToDelete: string[]): void {
    const grids = Object.assign({}, this.weedsCurated.grid);
    const skipSquares = [];
    const deleted = {};

    Object.entries(grids).forEach((grid) => {
      const key = grid[0];
      const color = Object.keys(grid[1])[0];
      const existingClass = this.weedsClassesColors[color];
      if (weedsClassesToDelete.includes(existingClass)) {
        deleted[key] = this.weedsCurated.grid[key];
        delete this.weedsCurated.grid[key];
      } else {
        skipSquares.push(this.weedsCurated.grid[key]);
      }
    });
    this.updateGeoJsonSource(this.smallSquaresSourceId, featureCollection(skipSquares));
    this.saveToHistory({
      added: {},
      deleted
    });
  }

  private deleteSquaresForPolygon(weedsClassesToDelete: string[]): void {
    const skipSquares = [];
    const deleted = {};

    this.smallSquaresForSelectedRegion.forEach((feature) => {
      const key = feature.properties.key;
      if (weedsClassesToDelete.includes(feature.properties.color)) {
        deleted[key] = this.weedsCurated.grid[key];
        delete this.weedsCurated.grid[key];
      } else {
        skipSquares.push(feature);
      }
    });
    this.updateGeoJsonSource(this.smallSquaresSourceId, featureCollection(skipSquares));
    this.saveToHistory({
      added: {},
      deleted
    });
  }

  private hasIntersectionWithParcel(f: Feature<Polygon>): boolean {
    const intersection = polygonClipping.intersection(
      f.geometry.coordinates as Ring[],
      this.parcel.Shape.features[0].geometry.coordinates as Ring[]
    );
    return intersection && intersection.length > 0;
  }

  private drawSquaresForPolygon(poly: Feature<Polygon>): void {
    const line = polygonToLine(poly) as Feature<LineString>;
    const lineTiles = tiles(line.geometry, {
      min_zoom: this.weedsCurated.zoom,
      max_zoom: this.weedsCurated.zoom
    });

    const skipTiles = this.getSkipTilesKeys(lineTiles, poly);
    let needRedraw = false;
    const added = {};
    const deleted = {};
    const polygonTiles = tiles(poly.geometry, {
      min_zoom: this.weedsCurated.zoom,
      max_zoom: this.weedsCurated.zoom
    });
    polygonTiles.forEach((tile) => {
      const key = tile[0] + '_' + tile[1];

      let drawModeCondition = !skipTiles.includes(key);
      if (this.isReclassifyMode) {
        drawModeCondition = !skipTiles.includes(key) && !!this.weedsCurated.grid[key];
      }

      if (drawModeCondition) {
        const smallFeature = feature(tileToGeoJSON(tile), {
          key,
          color: constants.weedsClassesColors[poly.properties.tag]
        });
        if (this.hasIntersectionWithParcel(smallFeature)) {
          needRedraw = true;
          if (this.weedsCurated.grid[key]) {
            deleted[key] = this.weedsCurated.grid[key];
          }
          this.weedsCurated.grid[key] = {
            [poly.properties.tag]: true
          };
          added[key] = this.weedsCurated.grid[key];
          const alreadyAdded = this.smallSquaresForSelectedRegion.find((feature) => feature.properties.key === key);
          if (alreadyAdded) {
            alreadyAdded.properties.color = constants.weedsClassesColors[poly.properties.tag];
          } else {
            this.smallSquaresForSelectedRegion.push(smallFeature);
          }
        }
      }
    });
    if (needRedraw) {
      this.updateGeoJsonSource(this.smallSquaresSourceId, featureCollection(this.smallSquaresForSelectedRegion));
      this.saveToHistory({
        added,
        deleted
      });
    }
  }

  private updateGeoJsonSource(sourceId: string, geoJsonData: FeatureCollection<Polygon>): void {
    const source = this.map.getSource(sourceId) as mapboxgl.GeoJSONSource; // set data available only for geoJSON;
    if (source) {
      source.setData(geoJsonData);
    }
  }

  private cleanupLayer(layerId: string, sourceId: string): void {
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
    if (this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId);
    }
  }

  private simplifyPolygon(polygon: Feature<Polygon>): Feature<Polygon> {
    const cv = cleanCoords(polygon);
    return simplify(cv, { tolerance: 0.000001 });
  }

  private saveToHistory(weedsDelta): void {
    if (this.drawHistory.length === 5) {
      this.drawHistory = this.drawHistory.slice(1);
    }
    this.drawHistory.push(weedsDelta);
  }

  applyFromHistory(): void {
    if (this.drawHistory.length > 1) {
      this.$store.dispatch('showGlobalLoader', true);
      window.setTimeout(() => {
        const index = this.drawHistory.length - 1;
        const weedsDelta = this.drawHistory[index];
        this.drawHistory = this.drawHistory.slice(0, index);
        Object.keys(weedsDelta.added).forEach((key) => {
          delete this.weedsCurated.grid[key];
        });
        Object.keys(weedsDelta.deleted).forEach((key) => {
          this.weedsCurated.grid[key] = weedsDelta.deleted[key];
        });
        this.initSquaresForSelectedRegion();
        this.updateGeoJsonSource(this.smallSquaresSourceId, featureCollection(this.smallSquaresForSelectedRegion));
        this.$store.dispatch('showGlobalLoader', false);
      }, 100);
    }
  }

  private initPaint(): void {
    this.isPaintMode = true;
    this.map.getCanvas().style.cursor = 'crosshair';
    this.draw.changeMode('paint', {
      color: constants.weedsClassesColors[this.selectedWeedsClass],
      tag: this.selectedWeedsClass
    });
  }

  paint(): void {
    this.isDeleteMode = false;
    this.isReclassifyMode = false;
    if (this.isPaintMode) {
      this.draw.changeMode('simple_select');
      this.isPaintMode = false;
      this.map.getCanvas().style.cursor = 'unset';
      return;
    }
    this.initPaint();
  }

  private initDeletePainted(): void {
    this.isDeleteMode = true;
    this.map.getCanvas().style.cursor = 'crosshair';
    this.draw.changeMode('paint', { color: '#000000' });
  }

  deletePainted(): void {
    this.isPaintMode = false;
    this.isReclassifyMode = false;
    if (this.isDeleteMode) {
      this.draw.changeMode('simple_select');
      this.isDeleteMode = false;
      this.map.getCanvas().style.cursor = 'unset';
      return;
    }
    this.initDeletePainted();
  }

  private initReclassifyPainted(): void {
    this.isReclassifyMode = true;
    this.map.getCanvas().style.cursor = 'crosshair';
    this.draw.changeMode('paint', {
      color: constants.weedsClassesColors[this.selectedWeedsClass],
      tag: this.selectedWeedsClass
    });
  }

  reclassifyPainted(): void {
    this.isPaintMode = false;
    this.isDeleteMode = false;
    if (this.isReclassifyMode) {
      this.draw.changeMode('simple_select');
      this.isReclassifyMode = false;
      this.map.getCanvas().style.cursor = 'unset';
      return;
    }
    this.initReclassifyPainted();
  }

  updateSelectedTileStatus(status: string) {
    this.tilesStatus.grid[this.selectedRegion.properties.key] = status;
    this.selectedRegion.properties.color = constants.tileStatusColors[status];
    this.updateGeoJsonSource(this.parcelSquaresSourceId, featureCollection(this.regions));
    return API.saveRegionsStatus(this.surveyId, this.tilesStatus);
  }

  async approveTile(): Promise<void> {
    await this.$store.dispatch('showGlobalLoader', true);
    await this.updateSelectedTileStatus(constants.tileStatus.APPROVED);
    const selectedRegionIndex = this.regions.findIndex((f) => f.properties.key === this.selectedRegion.properties.key);
    const nextNotApproved = this.regions
      .slice(selectedRegionIndex + 1)
      .find((region) => this.tilesStatus.grid?.[region.properties.key] !== constants.tileStatus.APPROVED);
    if (nextNotApproved) {
      const coordinates = center(nextNotApproved.geometry).geometry.coordinates;
      this.map.setCenter(new LngLat(coordinates[0], coordinates[1]));
    }
    this.onRegionClick(nextNotApproved ? nextNotApproved.properties.key : this.selectedRegion.properties.key);
    await this.$store.dispatch('showGlobalLoader', false);
  }

  async rejectTile(): Promise<void> {
    await this.$store.dispatch('showGlobalLoader', true);
    await this.updateSelectedTileStatus(constants.tileStatus.REJECTED);
    await this.$store.dispatch('showGlobalLoader', false);
  }

  getWeedsCurated(): Weeds {
    return this.weedsCurated;
  }

  drawInCurationAnalytic(): void {
    this.map.setLayoutProperty(this.smallSquaresLayerId, 'visibility', 'visible');
  }

  hideInCurationAnalytic(): void {
    this.map.setLayoutProperty(this.smallSquaresLayerId, 'visibility', 'none');
  }

  reDrawInCurationAnalytic(opacity: number): void {
    this.map.setPaintProperty(this.smallSquaresLayerId, 'line-opacity', opacity);
  }

  drawWeedsAnalytic(): void {
    this.map.setLayoutProperty(this.weedsAnalyticLayerId, 'visibility', 'visible');
  }

  hideWeedsAnalytic(): void {
    this.map.setLayoutProperty(this.weedsAnalyticLayerId, 'visibility', 'none');
  }

  reDrawWeedsAnalytic(opacity: number): void {
    this.map.setPaintProperty(this.weedsAnalyticLayerId, 'fill-opacity', opacity);
  }

  reDrawOriginalWeedsAnalytic(opacity: number): void {
    this.map.setPaintProperty(this.originalWeedsAnalyticLayerId, 'fill-opacity', opacity);
  }

  drawOriginalWeedsAnalytic(): void {
    this.map.setLayoutProperty(this.originalWeedsAnalyticLayerId, 'visibility', 'visible');
  }

  hideOriginalWeedsAnalytic(): void {
    this.map.setLayoutProperty(this.originalWeedsAnalyticLayerId, 'visibility', 'none');
  }

  drawInnerTiles(): void {
    this.map.setLayoutProperty(this.innerParcelSquaresLayerId, 'visibility', 'visible');
  }

  hideInnerTiles(): void {
    this.map.setLayoutProperty(this.innerParcelSquaresLayerId, 'visibility', 'none');
  }

  reDrawInnerTiles(opacity: number): void {
    this.map.setPaintProperty(this.innerParcelSquaresLayerId, 'line-opacity', opacity);
  }

  onChangeWeedsClass(): void {
    if (this.isPaintMode) {
      this.initPaint();
    }
    if (this.isDeleteMode) {
      this.initDeletePainted();
    }
    if (this.isReclassifyMode) {
      this.initReclassifyPainted();
    }
  }

  onClassCurationModeChange(e): void {
    if (!e.target.checked) {
      this.selectedWeedsClass = WeedsClassType.GW;
    }
    this.onChangeWeedsClass();
  }
}
