export enum ProcessingState {
  Initial = 'Initial',
  InProgress = 'InProgress',
  Finished = 'Finished',
  Failed = 'Failed'
}

export interface WeedsCurationData {
  totalCurationTime: number;
  curatorEmail: string;
  curatorName: string;
  curatorId: string;
  version?: string;
  isRecalculated?: boolean;
  processingState?: ProcessingState;
}
