import Vue from 'vue';
import VueRouter from 'vue-router';
import Curator from '@/views/Curator.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Curator',
    component: Curator
  },
  { path: '*', redirect: '/' }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
