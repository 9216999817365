
import { Component, Vue } from 'vue-property-decorator';
import Map from '@/components/Map/Map.vue';
import { MapComponent } from '@/interfaces/mapComponent';
import API from '@/services/api';
import { UserInfo } from '@/interfaces/userInfo';
import { message } from 'ant-design-vue';
import { BackgroundSource } from '@/enum/backgroundSource';
import { WeedsStatistics } from '@/interfaces/weedsStatistics';
import { WeedsCurationData, ProcessingState } from '@/interfaces/weedsCurationData';
import * as Sentry from '@sentry/vue';
import { AxiosError } from 'axios';

@Component({
  components: {
    Map
  }
})
export default class Curator extends Vue {
  $refs: {
    map: MapComponent;
  };
  isShowInCurationAnalytic = true;
  isShowAnalytic = true;
  isShowOriginal = false;
  areInnerTilesShown = true;
  isPublished = null;
  startCurationTime: Date;
  lastUpdate = null;
  backgroundSourceEnum = BackgroundSource;
  backgroundSource = BackgroundSource.DRONE;
  statistics: WeedsStatistics = { totalArea: 0, originalArea: 0, accuracy: 0, precision: 0, sensitivity: 0 };
  private inCurationOpacity = 100;
  private analyticOpacity = 100;
  private originOpacity = 100;
  private innerTilesOpacity = 100;
  private gridSize = 18;
  private refreshSessionTimeout = 600000; // millisecond, 10 min

  get isCurationStarted(): boolean {
    return this.$store.state.underCuration;
  }

  get surveyId(): string {
    return this.$route.query.surveyId as string;
  }

  get userInfo(): UserInfo {
    return this.$store.state.userInfo;
  }

  get weedsCurationData(): WeedsCurationData {
    return this.$store.state.weedsCurationData;
  }

  get isRecalculated(): boolean {
    return this.$store.state?.weedsCurationData?.isRecalculated;
  }

  get processingState(): ProcessingState {
    return this.$store.state?.weedsCurationData?.processingState;
  }

  get isStateInProgress(): boolean {
    return this.processingState === ProcessingState.InProgress;
  }

  async mounted(): Promise<void> {
    try {
      const response = await API.authorize('curator');
      const userInfo = response.UserInfo;
      this.$store.dispatch('setUserInfo', response.UserInfo);
      Sentry.setUser({ email: userInfo.Email, username: userInfo.FirstName + ' ' + userInfo.LastName });
    } catch (error) {
      window.location.href = `${process.env.VUE_APP_AUTH_SERVER}?redir=${encodeURIComponent(window.location.href)}`;
    }

    try {
      await this.loadAnalyticStatus();
    } catch (error) {
      API.handleError('Failed to load analytic status', error as AxiosError<any>);
    }

    await this.$store.dispatch('loadWeedsCurationData', this.surveyId);

    API.getStatistics(this.$route.query.surveyId as string, this.lastUpdate).then((statistics: WeedsStatistics) => {
      this.statistics = statistics;
    });

    setInterval(() => {
      /* eslint-disable-next-line no-console */
      console.info('refreshSession...');
      API.refreshSession();
    }, this.refreshSessionTimeout);
  }

  getAccuracyStatPerc(original: number, curated: number): string {
    if (typeof original === 'number' && curated > 0) {
      return `${(((original - curated) / curated) * 100).toFixed(2)}%`;
    }
    return '-';
  }

  getAccuracyStat(original: number, curated: number): string {
    if (typeof original === 'number' && curated >= 0) {
      return `${(curated - original).toFixed(2)}m2`;
    }
    return '-';
  }

  getWeedsGridStat(metric: number): string {
    if (metric !== undefined && metric !== null) {
      return `${metric.toFixed(2)}%`;
    }
    return '-';
  }

  async complete(): Promise<void> {
    this.$store.dispatch('showGlobalLoader', true);

    this.$refs.map.stopDrawing();
    try {
      await API.recalculateWeedsAnalytic(this.surveyId, this.$refs.map.getWeedsCurated());
      const curationTime = this.finishCuration(); // stop timer
      await API.updateWeedsCurationData(this.surveyId, {
        totalCurationTime: curationTime,
        curatorEmail: this.userInfo.Email,
        curatorName: `${this.userInfo.FirstName} ${this.userInfo.LastName}`,
        curatorId: this.userInfo.id
      });

      await this.loadAnalyticStatus();
      await this.$store.dispatch('loadWeedsCurationData', this.surveyId);

      message.info('Complete request is successfully sent! Check the state');
      // state tracker
      setTimeout(() => this.trackProcessingState(this.surveyId), 2000);
    } finally {
      this.$store.dispatch('showGlobalLoader', false);
    }
  }

  async saveDraft(): Promise<void> {
    this.$store.dispatch('showGlobalLoader', true);
    this.$refs.map.stopDrawing();
    try {
      await API.saveAnalyticCurated(this.surveyId, this.$refs.map.getWeedsCurated());
      await this.$store.dispatch('loadWeedsCurationData', this.surveyId);
    } finally {
      this.$store.dispatch('showGlobalLoader', false);
    }
  }

  async publish(): Promise<void> {
    this.$store.dispatch('showGlobalLoader', true);
    await API.publishAnalytic(this.$route.query.surveyId as string)
      .then(() => {
        this.isPublished = true;
      })
      .finally(() => {
        this.$store.dispatch('showGlobalLoader', false);
      });
  }

  async unPublish(): Promise<void> {
    this.$store.dispatch('showGlobalLoader', true);
    await API.unPublishAnalytic(this.$route.query.surveyId as string)
      .then(() => {
        this.isPublished = false;
      })
      .finally(() => {
        this.$store.dispatch('showGlobalLoader', false);
      });
  }

  showOriginal(): void {
    if (this.isShowOriginal) {
      this.$refs.map.drawOriginalWeedsAnalytic();
    } else {
      this.$refs.map.hideOriginalWeedsAnalytic();
    }
  }

  showInnerTiles(state?: boolean): void {
    if (this.areInnerTilesShown) {
      this.$refs.map.drawInnerTiles();
    } else {
      this.$refs.map.hideInnerTiles();
    }
  }

  handleToggleByKey(state: boolean) {
    this.areInnerTilesShown = state;
    this.showInnerTiles();
  }

  handleToggleShowAnalytic(): void {
    this.isShowAnalytic = !this.isShowAnalytic;
    this.showAnalytic();
  }

  showInCurationAnalytic(): void {
    if (this.isShowInCurationAnalytic) {
      this.$refs.map.drawInCurationAnalytic();
    } else {
      this.$refs.map.hideInCurationAnalytic();
    }
  }

  showAnalytic(): void {
    if (this.isShowAnalytic) {
      this.$refs.map.drawWeedsAnalytic();
    } else {
      this.$refs.map.hideWeedsAnalytic();
    }
  }

  changeInCurationAnalyticTransparency(): void {
    this.$refs.map.reDrawInCurationAnalytic(this.inCurationOpacity / 100);
  }

  changeAnalyticTransparency(): void {
    this.$refs.map.reDrawWeedsAnalytic(this.analyticOpacity / 100);
  }

  changeOriginalTransparency(): void {
    this.$refs.map.reDrawOriginalWeedsAnalytic(this.originOpacity / 100);
  }

  changeInnerTilesTransparency(): void {
    this.$refs.map.reDrawInnerTiles(this.innerTilesOpacity / 100);
  }

  finishCuration(): number {
    if (!this.isCurationStarted) {
      return 0;
    }
    const endTime = new Date();
    /* eslint-disable-next-line no-console */
    console.info('endTime', endTime);
    this.$store.dispatch('setUnderCuration', false);
    return endTime.getTime() - this.startCurationTime.getTime();
  }

  startCuration(): void {
    this.isShowAnalytic = true;
    this.startCurationTime = new Date();
    this.$store.dispatch('setUnderCuration', true);
    /* eslint-disable-next-line no-console */
    console.info('startCurationTime', this.startCurationTime);
  }

  openLabelling(): void {
    window.open(process.env.VUE_APP_LABELLING + '?surveyId=' + this.surveyId, '_blank');
  }

  async loadAnalyticStatus(): Promise<void> {
    if (!this.surveyId) {
      throw new Error('No survey id');
    }
    const data = await API.getAnalyticStatus(this.surveyId, 'weeds');
    if (data) {
      if (!data || !data.Published) {
        const data = await API.getAnalyticStatus(this.surveyId, 'weeds_classes');
        if (data) {
          this.isPublished = !!data.Published;
          this.lastUpdate = data.LastUpdate;
        } else {
          this.lastUpdate = `${new Date().getTime()}`;
        }
      } else {
        this.isPublished = !!data.Published;
        // TODO: get last update date based on file from storage
        this.lastUpdate = data.LastUpdate;
      }
    }
  }

  async trackProcessingState(surveyId: string): Promise<void> {
    await this.$store.dispatch('loadWeedsCurationData', this.surveyId);
    if (this.processingState === ProcessingState.Initial || this.processingState === ProcessingState.InProgress) {
      // recursive run
      setTimeout(() => this.trackProcessingState(surveyId), 5000);
    }
  }
}
