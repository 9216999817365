import { WeedsClassLabel, WeedsClassType } from '@/enum/weeds-class-type';
import { WeedsClassKeys } from '@/enum/weedsClassesKeys';

export default class Constants {
  static weedsClassesColors = {
    GW: '#E94463',
    BRLOW: '#F09798',
    CW: '#FF6700',
    BRHIW: '#D05BFE',
    default: '#ebdb34'
  };

  static tileStatus = {
    APPROVED: 'approved',
    ORIGINAL: 'original',
    REJECTED: 'rejected'
  };

  static tileStatusColors = {
    [Constants.tileStatus.APPROVED]: 'green',
    [Constants.tileStatus.ORIGINAL]: 'red',
    [Constants.tileStatus.REJECTED]: 'yellow'
  };

  static roles = {
    OPERATOR: 'operator',
    ADMIN: 'admin',
    MAHINDRA_ADMIN: 'mahindra_admin',
    ADVANCED_ADMIN: 'advanced_admin'
  };

  static weedsClassesKeysMap = {
    [WeedsClassKeys.GW]: WeedsClassType.GW,
    [WeedsClassKeys.BRLOW]: WeedsClassType.BRLOW,
    [WeedsClassKeys.CW]: WeedsClassType.CW,
    [WeedsClassKeys.BRHIW]: WeedsClassType.BRHIW
  };

  static weedsClassesOptions = [
    {
      value: Constants.weedsClassesColors.GW,
      key: WeedsClassType.GW,
      label: WeedsClassLabel.GW
    },
    {
      value: Constants.weedsClassesColors.BRLOW,
      key: WeedsClassType.BRLOW,
      label: WeedsClassLabel.BRLOW
    },
    {
      value: Constants.weedsClassesColors.CW,
      key: WeedsClassType.CW,
      label: WeedsClassLabel.CW
    },
    {
      value: Constants.weedsClassesColors.BRHIW,
      key: WeedsClassType.BRHIW,
      label: WeedsClassLabel.BRHIW
    }
  ];
}
