import { WeedsCurationData } from '@/interfaces/weedsCurationData';
import Vue from 'vue';
import Vuex from 'vuex';
import { UserInfo } from '@/interfaces/userInfo';
import { State } from '@/interfaces/state';
import API from '@/services/api';
import { message } from 'ant-design-vue';

Vue.use(Vuex);

export default new Vuex.Store<State>({
  state: {
    showGlobalLoader: false,
    userInfo: null,
    underCuration: false,
    weedsCurationData: null
  },
  mutations: {
    showGlobalLoader(state, payload) {
      state.showGlobalLoader = payload;
    },
    setUserInfo: (state, userInfo: UserInfo) => {
      state.userInfo = userInfo;
    },
    setUnderCuration: (state, payload: boolean) => {
      state.underCuration = payload;
    },
    setWeedsCurationData: (state, payload: WeedsCurationData) => {
      state.weedsCurationData = payload;
    }
  },
  actions: {
    showGlobalLoader({ commit }, isShow) {
      commit('showGlobalLoader', isShow);
    },
    setUserInfo: ({ commit }, userInfo: UserInfo) => {
      commit('setUserInfo', userInfo);
    },
    setUnderCuration: ({ commit }, isUnderCuration: boolean) => {
      commit('setUnderCuration', isUnderCuration);
    },
    loadWeedsCurationData: async ({ commit }, surveyId: string) => {
      const weedsCurationData = await API.getWeedsCurationData(surveyId);
      if (!weedsCurationData) {
        message.error(`Could not load weeds data from curation service for survey ${surveyId}`, 5);
      }

      commit('setWeedsCurationData', weedsCurationData);
    }
  },
  modules: {}
});
